.footer-link {
	display: inline-block;
	margin-right: 30px;
}

footer p {
	margin-bottom: 0;
	height: 100%;
	margin-top: 20px;
}

@media (max-width: 767px) {



	footer .col-md-4:nth-child(1) {
		text-align: center;
		margin-bottom: 20px;
	}

	footer .col-md-1:nth-child(2) {
		text-align: center;
	}

	footer .col-md-4:nth-child(3) {
		text-align: center;
	}

}


footer {
	padding: 100px 0;
	position: relative;
	
	&:before {
		content: "";
		width: 100%;
		position: absolute;
		height: 600px;
		bottom: 0;
		//transform: translateY(-500px);
		background: url(../img/footer-bg.jpg) top center no-repeat;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
}

footer .col {
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

footer .col-md-4:nth-child(1) {
	text-align: right;
	
	@media #{$phone}{
		text-align: center;
	}
}

footer .col-md-1:nth-child(2) {
	text-align: center;
}

footer .col:nth-child(3) {
	text-align: left;
}

footer p {
	text-transform: uppercase;
	font-size: 14px;
	color: #FFF;
}

footer p a {
	color: #FFF;
}

.footer-logo {

	animation:pulse 1.5s infinite;
}

@keyframes pulse {
	10% {transform: scale(1.1)}
}