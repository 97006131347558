.container {
	width: 1180px;
	position: relative;
	max-width: 1180px;
	margin: 0 auto;
	
	@media #{$phone} {
		width: 100%;
		max-width: 100%;
		padding: 0 20px;
	}
}


.row {
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-flex-direction: row;
	    -ms-flex-direction: row;
	        flex-direction: row;
	
	@media #{$phone}{
		-webkit-flex-direction: column;
		    -ms-flex-direction: column;
		        flex-direction: column;
	}
}

.col {
	@media #{$phone}{
		width: 100% !important;
		margin-bottom: 20px !important;
	}
}






