@import url('https://fonts.googleapis.com/css2?family=Istok+Web:wght@400;700&display=swap');

html{
	-ms-overflow-x: hidden;
	    overflow-x: hidden;
}

body {
	background: #F7F6F1;
	color: #fff;
	overflow-x: hidden;
	font-family: 'Istok Web', sans-serif;
	font-weight: 400;
	font-size: 16px;
	text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
	-ms-overflow-x: hidden;
	    overflow-x: hidden;
	
	@media #{$phone}{
		width: 100%;
		font-size: 16px;
		-ms-overflow-x: hidden;
		    overflow-x: hidden;
		//background: #FFF;
	}
}

body::-webkit-scrollbar {
  width: 0.5em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #000;
  outline: 1px solid slategrey;
}

p {
	color: #000;
	font-size: 16px;
	line-height: 1.3;
}

img {
	@media #{$phone}{
		max-width: 100%;
		//width: 100%;
	}
}

.header {
	margin-bottom: 40px;
}

section {
	position: relative;
	z-index: 10;
}

.devider {
	display: block;
	margin: 30px 0;
	width: 85px;
	height: 26px;
	background: url(../img/separator.svg) left center no-repeat;
	-webkit-background-size: contain;
	     -o-background-size: contain;
	        background-size: contain;
}



.hero {
	height: 800px;
	
	@media #{$phone}{
		z-index: 50;
	}
	

	&.home {
		background: url(../img/home-h-bg.jpg) center no-repeat;
		-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
		    
		.main-logo {
			margin-top: 30vh;
		}

		.char {
			
			@media #{$phone}{
				display: none;
			}

		}
	}

	&.h-games {
		height: auto;
		background: url(../img/games-h-bg.jpg) top center no-repeat;
	        background-size: 100%;
	        

	     @media #{$phone}{
	     	background-size: 250%;
	     }
		    
		.menu {
			a {
				color: #FFF;
			}
		}

		h1 {
			text-align: center;
			font-size: 64px;
			font-weight: bold;
			margin-top: 100px;
			margin-bottom: 200px;
		}
	}

	&.h-news {
		height: auto;
		background: url(../img/news-h-bg.jpg) top center no-repeat;
	    background-size: 100%;
	        
	    @media #{$phone}{
	     	background-size: 250%;
	     }
		    
		.menu {
			a {
				color: #FFF;
			}
		}

		h1 {
			text-align: center;
			font-size: 64px;
			font-weight: bold;
			margin-top: 100px;
			margin-bottom: 150px;
		}
	}

	&.h-news-detail {
		height: auto;
		padding-bottom: 200px;
		background: url(../img/news-detail-h-bg.jpg) top center no-repeat;
	    background-size: 100%;
	    
	    @media #{$phone}{
	    	background: url(../img/news-detail-h-bg.jpg) top right no-repeat;
	    	background-size: 200%;
	    }
	        
	    .middle {
	    	padding-top: 100px;
	    	.container {
		    	max-width: 650px;
		    	
		    	.img-right {
		    		position: absolute;
		    		right: -60%;
		    		
		    		@media #{$phone}{
		    			display: none;
		    		}
		    	}

		    	.img-left {
		    		position: absolute;
		    		left: -60%;
		    		top: 20%;
		    		
		    		@media #{$phone}{
		    			display: none;
		    		}
		    	}
		    	
		    	.date {
		    		display: block;
		    		text-align: center;
		    		font-size: 16px;
		    		margin-bottom: 40px;
		    		color: #22221F;
		    	}
		    	
		    	h1 {
		    		font-size: 64px;
		    		text-align: center;
		    		margin-bottom: 100px;
		    		color: #000;
		    		font-weight: bold;
		    	}

		    	.devider {
		    		margin: 30px auto;
		    	}

		    	p {
		    		margin-bottom: 40px;
		    		
		    	}

		    	img {
		    		margin-bottom: 40px;
		    		-webkit-border-radius: 15px;
		    		        border-radius: 15px;
		    	}
		    }
	    }
	        
	    
	}

	&.h-contacts {
		height: auto;
		background: url(../img/contacts-h-bg.jpg) top center no-repeat;
	    background-size: 100%;
	    padding-bottom: 400px;
	        
	    h1 {
	    	margin-top: 200px;
	    	margin-bottom: 100px;
	    	font-size: 64px;
	    	font-weight: bold;
	    	color: #000;
	    }

	    .globus {
	    	@media #{$phone}{
	    		display: none;
	    	}
	    }

	    .col-border {
	    	position: relative;
	    	
	    	&:after {
	    		content: "";
	    		display: block;
	    		position: absolute;
	    		right: 0;
	    		top: 0;
	    		width: 26px;
	    		height: 229px;
	    		background: url(../img/separator-v.svg) center no-repeat;
	    		-webkit-background-size: contain;
	    		     -o-background-size: contain;
	    		        background-size: contain;
	    		
	    		@media #{$phone}{
			     	transform:rotate(90deg);
			     	height: 26px;
			     	width: 229px;
			     	position: static;
			     	transform-origin: center;
			     	display: none;
			     }
	    	}
	    }

	    h3 {
	    	color: #000;
	    	font-size: 16px;
	    	padding-left: 50px;
	    	margin-bottom: 20px;
	    	
	    	@media #{$phone}{
	    		padding-left: 0;
	    		font-size: 24px;
	    		font-weight: bold;
	    	}
	    }

	    .field {
	    	margin-bottom: 40px;
	    	
	    	span {
	    		font-size: 14px;
	    		color: #8C8C70;
	    	}

	    	p {
	    		font-size: 16px;
	    		color: #435C79;
	    	}

	    	a {
	    		margin-right: 20px;
	    		color: #435C79;
	    	}
	    }
	}

	&.h-games-single {
		height: auto;
		padding-bottom: 400px;
		
		@media #{$phone}{
			padding-bottom: 200px;
		}
		
		.game-thumb {
			    position: absolute;
			    right: 9%;
			    top: 272px;
			    width: 300px;
			    
			@media #{$phone}{
				display: none;
			}
			
		}
		h1 {
			color: #000;
			margin-top: 200px;
			font-size: 64px;
			font-weight: bold;
			width: 80%;
			
			@media #{$phone}{
				font-size: 42px;
			}
		}

		h4 {
			font-size: 24px;
			font-weight: bold;
			color: #000;
			margin-bottom: 20px;
		}

		p {
			font-size: 24px;
			margin-bottom: 40px;
			
			@media #{$phone}{
				font-size: 18px;
			}
		}

		.download {
			font-size: 32px;
			color: #000;
			margin-right: 20px;
			transform: translateY(-22px);
			display: inline-block;
		}
	}

	&.h-games-loe {
		background: url(../img/games/loe-h-bg.jpg) top center no-repeat;
	        background-size: 100%;
	}

	&.h-games-cat {
		background: url(../img/games/cat-h-bg.jpg) top center no-repeat;
	        background-size: 100%;
	}

	&.h-games-wb {
		background: url(../img/games/wb-h-bg.jpg) top center no-repeat;
	        background-size: 100%;
	}

	&.h-games-wh {
		background: url(../img/games/wh-h-bg.jpg) top center no-repeat;
	        background-size: 100%;
	}

	&.h-games-art {
		background: url(../img/games/art-h-bg.jpg) top center no-repeat;
	        background-size: 100%;
	}

	&.h-about {
		background: url(../img/about-h-bg.jpg) top center no-repeat;
	        background-size: 100%;
	        
	    p {
	    	margin-bottom: 40px;
	    	
	    	strong {
	    		font-weight: bold;
	    	}
	    	
	    	a {
	    		color: #334457;
	    	}
	    }
	}
}

.games {
	padding-top: 80px;
	padding-bottom: 100px;
	
	@media #{$phone}{
		padding-top: 120px;
		padding-bottom: 0;
	}

	.col-md-4 {
		&:first-child {
			margin-bottom: 150px;
		}

		@media #{$phone}{
			margin-bottom: 150px;
		}
	}
	
	.card {
		border: none;
		padding: 120px 30px 30px;
		background: #FFF;
		-webkit-border-radius: 15px;
		        border-radius: 15px;
		
		&.card-all {
			background: #AFBFC4;
			
			a {
				display: block;
				margin: 0 auto;
				
				&:nth-child(2) {
					margin-bottom: 20px;
				}
				
				
				img {
					position: static;
					transform: none;
				}
			}
		}
		
		img {
			position: absolute;
			width: 200px;
			left: 50%;
			transform: translateX(-50%);
			top: -40%;
		}

		h2 {
			font-size: 24px;
			color: #000;
			font-weight: bold;
			margin-top: 20px;
			margin-bottom: 20px;
			
			a {
				color: #000;
				text-decoration: none;
			}
		}

		p {
			font-size: 14px;
			opacity: 0.8;
		}

		span {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			width: 85px;
			height: 26px;
			bottom: -14px;
			background: url(../img/separator.svg) left center no-repeat;
			-webkit-background-size: contain;
			     -o-background-size: contain;
			        background-size: contain;
		}
	}
}

.news {
	.card {
		border: none;
		position: relative;
		margin-bottom: 30px;
		padding: 20px 30px 40px;
		-webkit-border-radius: 15px;
		        border-radius: 15px;
		    
		h2 {
			font-size: 24px;
			color: #000;
			font-weight: bold;
			margin-top: 20px;
			margin-bottom: 20px;
			width: 60%;

			
			a {
				color: #000;
				text-decoration: none;
			}
		}

		

		p {
			font-size: 14px;
			opacity: 0.8;
		}

		span {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			width: 85px;
			height: 26px;
			bottom: -14px;
			background: url(../img/separator.svg) left center no-repeat;
			-webkit-background-size: contain;
			     -o-background-size: contain;
			        background-size: contain;
		}

		.date {
			position: absolute;
			background: none;
			font-size: 14px;
			right: -20px;
			left: auto;
			bottom: auto;
			top: 40px;
			color: #8C8C70;
		}
	}
}

.about {
	padding: 100px 0;
	background: url(../img/about-bg.png) top center no-repeat;
	background-size: cover;
	
	@media #{$phone}{
		padding: 0;
		background: url(../img/about-bg.png) top center no-repeat;
		background-size: 100%;

	}
	
	.char {
		width: 90%;
		transform:translateY(180px);
		
		@media #{$phone}{
			transform:translateY(-50px);
		}

	}

	.content {
		padding-top: 300px;
		padding-left: 100px;
		
		@media #{$phone}{
			padding: 20px 20px;
		}
	}

	.socials {
		a {
			margin-left: 0;
			margin-right: 20px;
		}
	}
}

.forinvestors {

	.content {
		padding-left: 80px;
		
		@media #{$phone}{
			padding-left: 20px;
		}
	}
	
	h3 {
		padding-top: 200px;
		font-size: 48px;
		letter-spacing: 1%;
		font-weight: bold;
		color: #000;
	}

	.char {
		transform:translateY(-5%);
		
		@media #{$phone}{
			transform:translateY(0);
		}
	}
}


form {
	padding-left: 50px;
	
	@media #{$phone}{
		padding-left: 0;
	}
	
	.input-field {
		background: none;
		color: #8C8C70;
		width: 100%;
		padding: 10px 0;
		font-size: 16px;
		margin-bottom: 20px;
		border: none;
		border-bottom: 1px solid #435C79;
		
		&:active, &:focus {
			border: none;
			outline: none;
			border-bottom: 1px solid $blue;
			-webkit-border-radius: 0;
			        border-radius: 0;
		}
	}


}




