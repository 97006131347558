// Color Palette
$purple: #844BFF;
$primary-dark: #003F57;
$blue: #4791FF;
$white: #fff;
$black: lighten(#000, 20);
$zero: transparent;

//EASING
$ease: cubic-bezier(.34,.94,.59,.99);

// Media queries
$phone: "only screen and (max-width: 1024px)";
$lsphone: "only screen and (max-width: 767px) and (orientation: landscape)";
$nonphone: "only screen and (min-width: 769px)";
$tablet: "only screen and (min-width: 768px) and (max-width: 1170px)";
$lstablet: "only screen and (min-width: 768px) and (max-width: 1170px) and (orientation: landscape)";
$desktop: "only screen and (min-width: 768px) and (max-width: 2800px)";
$large: "only screen and (min-width: 1920px)";