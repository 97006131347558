.btn {
	color: #FFF;
	background: #435C79;
	width: 100%;
	text-transform: uppercase;
	position: relative;
	display: inline-block;
	height: 48px;
	line-height: 50px;
	padding: 0;
	letter-spacing: 0.3em;
	border: none;
	-webkit-border-radius: 10px;
	        border-radius: 10px;
	transition: letter-spacing 0.8s $ease;
	
	@media #{$phone}{
		width: 100%;
	}
	
	&:hover {
		background: #435C79;
		border: none;
		-webkit-box-shadow: none;
		        box-shadow: none;
		letter-spacing: 0.4em;
		transition: letter-spacing 0.8s $ease;

	}
	


	&:after {
		content: "";
		position: absolute;
		width: 46px;
		height: 44px;
		padding: 2px;
		-webkit-border-radius: 8px;
		        border-radius: 8px;
		background-color: #F7F6F1;
		background-image: url(../img/icons/arrow.svg);
		background-repeat: no-repeat;
		background-position: center;
		-webkit-background-size: 50%;
		     -o-background-size: 50%;
		        background-size: 50%;
		top: 2px;
		right: 2px;
	}
}

.more {
	color: $blue !important;
	display: block;
	letter-spacing: 0em;
	transition: letter-spacing 0.8s $ease;
	
	&:hover {
		letter-spacing: 0.1em;
		transition: letter-spacing 0.8s $ease;
	}
	
	&:after {
		content: '';
		display: inline-block;
		margin-left: 5px;
		width: 18px;
		height: 15px;
		background: url(../img/icons/arrow-icon.svg) center no-repeat;
		-webkit-background-size: contain;
		     -o-background-size: contain;
		        background-size: contain;
	}
}

.back {
	color: $blue !important;
	display: block;
	letter-spacing: 0em;
	transition: letter-spacing 0.8s $ease;
	
	&:hover {
		letter-spacing: 0.1em;
		transition: letter-spacing 0.8s $ease;
	}
	
	&:before {
		content: '';
		display: inline-block;
		margin-right: 5px;
		width: 18px;
		height: 15px;
		background: url(../img/icons/arrow-icon-b.svg) center no-repeat;
		-webkit-background-size: contain;
		     -o-background-size: contain;
		        background-size: contain;
	}
}













